<template>
  <div>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <v-snackbar
            top
            v-model="snackbar"
        >
          {{ msg }}
          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text

                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <v-card style="border-radius: 30px;" elevation="11" color="#f4f5f8" class="pa-4">
          <v-card-title>
            Contact Us
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="data.name"
                    color="#39bf88"
                    label="Full Name"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="data.phone"
                    color="#39bf88"
                    label="Phone Number"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="data.email"
                    color="#39bf88"
                    type="email"
                    label="Email"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="address"
                    label="Address"
                    outlined
                    color="#39bf88"
                    rows="3">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="data.body"
                    label="Comments"
                    outlined
                    color="#39bf88"
                    rows="10">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="1">

              </v-col>
              <v-col md="8" sm="10">
                <v-row>
                  <v-col cols="6">
                    <v-btn class="text-capitalize" style="color:white;font-size:16px; box-shadow: none!important;" height="60px" width="210px" rounded color="#fdbd68" @click="cancel()">Cancel</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn class="text-capitalize" style="color:white;font-size:16px; box-shadow: none!important;" height="60px" width="210px" rounded color="#39bf88" @click="submit()">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="2" sm="1">

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => {
    return {
      data: [],
      snackbar:false,
      msg:'',
      address:''
    }
  },
  methods:{
    cancel(){
      this.data=[]
    },
    submit(){
      window.axios.post('api/contact-us/create',{
        data:{
          name:this.data.name,
          phone:this.data.phone,
          email:this.data.email,
          address:this.address,
          type:'contact-us',
          body:this.data.body
        }
      }).then(()=>{
        this.snackbar=true
        this.msg='Your comments sent successfully'
      }).catch((err)=>{
        this.snackbar=true
        this.msg=err.response.data
      })
    }
  }
}
</script>

<style scoped>

</style>